import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { Form } from "react-bootstrap";
import { Input, TextArea, Button } from "../Core";
import { withNamespaces } from "react-i18next";

const ContactForm = ({ theme = "dark", t, ...rest }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_nu7q9kf",
      "template_ubjotjh",
      form.current,
      "user_Xgfl23887hg6rH6kqS9LG"
    );
  };

  return (
    <Form
      ref={form}
      onSubmit={sendEmail}
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      {...rest}
    >
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <div className="mt-4">
        <Input type="text" placeholder={t("formName")} required name="name" />
      </div>
      <div className="mt-4">
        <Input
          type="email"
          placeholder={t("formEmail")}
          required
          name="email"
        />
      </div>
      <div className="mt-4">
        <Input
          type="text"
          placeholder={t("formSubject")}
          required
          name="subject"
        />
      </div>
      <div className="mt-4 ">
        <TextArea rows={4} placeholder={t("formMsg")} required name="message" />
      </div>
      <div className="mt-4 mt-lg-5">
        <Button arrowRight variant="primary" type="submit">
          {t("formBtn")}
        </Button>
      </div>
    </Form>
  );
};

export default withNamespaces()(ContactForm);
