import React from "react";
import { Element } from "react-scroll";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import Contact from "../components/Contact/Contact";
import Projects from "../components/Projects/Projects";

const ProjectsPage = () => {
  return (
    <>
      <PageWrapper darkTheme>
        {/* <Hero /> */}
        <Element name="works">
          <Projects />
        </Element>
        <Contact />
      </PageWrapper>
    </>
  );
};
export default ProjectsPage;
