import CTA from "../../sections/home/CTA";
import Hero from "../../sections/home/Hero";
import Works from "../../sections/home/Works";
import PageWrapper from "../PageWrapper/PageWrapper";

export default function Home() {
  return (
    <>
      <PageWrapper darkTheme>
        <Hero />
        <Works name="works" />
        <CTA />
      </PageWrapper>
    </>
  );
}
