import "./public/assets/fonts/icon-font/css/style.css";
import "./components/Layout/bootstrap-custom.scss";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/aos/dist/aos.css";
import { theme } from "./utils";
import { ThemeProvider } from "styled-components";
import Layout from "./components/Layout";
import { GlobalProvider } from "./context/GlobalContex";
import Routes from "./Routes";

export default function App(/* { Component, ...pageProps } */) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalProvider>
        <Layout>
          {/*   <Component {...pageProps} /> */}
          <Routes />
        </Layout>
      </GlobalProvider>
    </ThemeProvider>
  );
}
