import React from "react";
import PropTypes from "prop-types";
import { FaFileAlt } from "react-icons/fa";
import styled from "styled-components";
import { Button } from "../Core";

export const FilePlaceholderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  transition: 0.4s ease-out;
  user-select: none;

  &:hover,
  &:focus,
  &:active {
    transform: translateY(-10px);
  }
`;

export const FileIcon = styled.div`
  color: ${({ theme }) => theme.colors.primary}!important;
  font-size: 50px;
  margin-right: 1.5px;
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FileName = styled.div`
  flex-grow: 1;
  padding-bottom: 5px;
  text-align: center;
`;

export const ViewButton = styled.div`
  background-color: #495fef;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;
  height: 40px;
  width: 80px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.63px;
  border-radius: 50rem;
  text-transform: uppercase;
`;

const FilePlaceholder = ({ fileName, fileId, fileUrl }) => {
  return (
    <FilePlaceholderStyled>
      <FileIcon>
        <FaFileAlt />
      </FileIcon>
      <FileInfo>
        {fileName.length < 30 ? (
          <FileName>{fileName}</FileName>
        ) : (
          <FileName>{fileName}...</FileName>
        )}
      </FileInfo>
      <ViewButton
        onClick={(e) => {
          e.preventDefault();
          window.open(fileUrl, "_blank");
        }}
      >
        View
      </ViewButton>
    </FilePlaceholderStyled>
  );
};

FilePlaceholder.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileId: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
};

export default FilePlaceholder;
