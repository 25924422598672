import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import GlobalContext from "../../context/GlobalContex";
import { Section, Title, ButtonIcon, LinkContact } from "../../components/Core";
import Availability from "../../components/Availability/Availability";

const CTA = ({ t }) => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <Section>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="10" xl="7">
              <Availability />
              <div className="text-center my-5">
                <Title>{t("getInTouch")}</Title>
              </div>
              <div className="text-center">
                <ButtonIcon
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}
                >
                  {t("projectsTitle")}
                </ButtonIcon>
              </div>
            </Col>
          </Row>
          <div className="d-flex flex-column flex-lg-row justify-content-center pt-5">
            <LinkContact
              href="https://linkedin.com/in/petar-gojun"
              target="_blank"
              className="mb-2 mb-lg-0"
            >
              Created by Petar Gojun
            </LinkContact>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default withNamespaces()(CTA);
