import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, LinkContact } from "../../components/Core";
import GlobalContext from "../../context/GlobalContex";
import axios from "axios";
import { withNamespaces } from "react-i18next";

const Hero = ({ t }) => {
  const gContext = useContext(GlobalContext);

  const [email, setEmail] = useState("");

  async function fetchEmail() {
    const response = await axios.get("https://nikol-api.fly.dev/user/1");

    setEmail(response.data.email);
  }

  useEffect(() => {
    fetchEmail();
  }, []);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section className="position-relative">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="11" xl="10" className="text-center">
              <Box>
                <Title variant="secSm">{t("getInTouch")}</Title>

                <Box
                  className="text-center d-flex justify-content-center"
                  mt={["3rem", null, "4rem"]}
                >
                  <div className="d-flex flex-column flex-lg-row justify-content-center">
                    <LinkContact
                      href={`mailto:${email}`}
                      target="_blank"
                      className="mb-2 mb-lg-0"
                    >
                      {gContext.email}
                    </LinkContact>
                  </div>
                </Box>
                <div className="d-flex flex-column flex-lg-row justify-content-center pt-5">
                  <LinkContact
                    href="https://linkedin.com/in/petar-gojun"
                    target="_blank"
                    className="mb-2 mb-lg-0"
                  >
                    Created by Petar Gojun
                  </LinkContact>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default withNamespaces()(Hero);
