import React from "react";
import { Element } from "react-scroll";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import Contact from "../components/Contact/Contact";
import Posts from "../components/Posts/Posts";

const PostsPage = () => {
  return (
    <>
      <PageWrapper darkTheme>
        {/* <Hero /> */}
        <Element name="works">
          <Posts />
        </Element>
        <Contact />
      </PageWrapper>
    </>
  );
};
export default PostsPage;
