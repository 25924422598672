import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { Section, Title, Text, Span } from "../../components/Core";
import GlobalContext from "../../context/GlobalContex";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, t, bg = "dark", ...rest }) => {
  const gContext = useContext(GlobalContext);

  const [about, setAbout] = useState("");

  const language = gContext.language;

  const user = gContext.user;

  function changeLanguage(language) {
    if (language === "en") {
      setAbout(user.enAbout);
    } else if (language === "bh") {
      setAbout(user.bhAbout);
    } else if (language === "nl") {
      setAbout(user.nlAbout);
    }
  }

  useEffect(() => {
    if (user) {
      changeLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img
                  src={user.profileImage.image.url}
                  alt="folio"
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  {user.firstName}&nbsp;{user.lastName}
                </Title>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  {about}
                </Text>
                <div className="mt-4">
                  <Text color="light">{t("formEmailMe")}</Text>

                  <Text variant="p">
                    <a
                      href={`mailto:${user.email}`}
                      className="font-weight-bold"
                    >
                      <Span color="light">{user.email}</Span>
                    </a>
                  </Text>
                </div>
                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="https://www.instagram.com/nikols_art/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bold"
                  >
                    Instagram
                  </LinkSocial>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default withNamespaces()(About);
