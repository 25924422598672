export const menuItems = [
/*   {
    name: "demos",
    label: "creative demos",
    items: [
      { name: "", label: "designer 01" },
      { name: "designer-2", label: "designer 02" },
      { name: "devloper-1", label: "devloper 01" },
      { name: "devloper-2", label: "devloper 02" },
      { name: "agency-1", label: "agency 01" },
      { name: "agency-2", label: "agency 02" },
      { name: "photography-1", label: "photography 01" },
      { name: "photography-2", label: "photography 02" },
    ],
  }, */
/*   { 
    name: "",
    label: "Home" 
  },
  {
    name:"masonry-2-column",
    label:"Projects"
  },
  { 
    name: "about",
    label: "About Me" 
  },
  {
    name:"contact",
    /* label:"Contact" 
    label:`{t('navContact')}`

  }, */
  {
    name: "pages",
    label: "Languages",
    items: [
      {
        name: "",
        label: "English",
        lang:"en"
      },
      {
        name: "",
        label: "Nederlands",
        lang:"nl"

      },
      {
      name: "",
      label: "Bosanski",
      lang:"bh"

      }
/*       {
        name: "portfolio-details",
        label: "portfolio Details",
        items: [
          { name: "portfolio-details", label: "details 01" },
          { name: "portfolio-details-2", label: "details 02" },
        ],
      },
      { name: "about", label: "about" },
      { name: "contact", label: "contact" }, */
    ],
  },
];
