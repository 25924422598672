import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import CTA from "../sections/common/CTA";
import { withNamespaces } from "react-i18next";
import { device } from "../utils";
import GlobalContext from "../context/GlobalContex";
import FilePlaceholder from "./../components/FilePlaceholder/FilePlacehoder";

const ProjectDetail = ({ project, t }) => {
  const gContext = useContext(GlobalContext);
  const language = gContext.language;

  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">
                    {
                      project.projectTranslations.find(
                        (translation) => translation.locale === language
                      )?.description
                    }
                  </Text>
                  <Title className="my-4">
                    {
                      project.projectTranslations.find(
                        (translation) => translation.locale === language
                      )?.title
                    }
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    {
                      project.projectTranslations.find(
                        (translation) => translation.locale === language
                      )?.body
                    }
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        {project.projectAttachments ? (
          <Row className="justify-content-center text-center">
            <Col lg="8">
              <Box>
                {project.projectAttachments.map((doc, i) => {
                  return (
                    <FilePlaceholder
                      fileName={`${doc.url
                        .split("/")
                        .pop()
                        ?.slice(17)
                        .slice(0, 10)}...`}
                      fileUrl={doc.url}
                      fileId={doc.id}
                    />
                  );
                })}
              </Box>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Section className=" pb-0">
          <Container>
            <Row>
              {project.projectImages.map((image, i) => {
                return (
                  <Col xs="12" className="mb-5">
                    <img
                      src={image.image.url}
                      alt={`assdasd${i}`}
                      key={`image${i}`}
                      className="img-fluid w-100"
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Section>

        <Section className="pt-0">
          <Container>
            <div className="text-center mt-lg-5">
              <a href="/projects">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  {t("projectsLink")}
                </Button>
              </a>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default withNamespaces()(ProjectDetail);
