import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text } from "../../components/Core";
import { withNamespaces } from "react-i18next";
import axios from "axios";

function Hero({ t }) {
  const [user, setUser] = useState([]);

  async function fetchUser() {
    const response = await axios.get(`https://nikol-api.fly.dev/user/1`);
    setUser(response.data);
  }

  function goToCv() {
    window.location.replace(`${user.cv}`);
  }

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row /* className="align-items-center" */>
            <Col /* lg="7" */ className="mb-5 mb-lg-0">
              <Box>
                <Text
                  variant="tag"
                  mb={4}
                  className="text-uppercase"
                  color="heading"
                >
                  {t("heroPreHead")}
                </Text>
                <Title variant="hero">{t("title")}</Title>

                <Box mt="52px">
                  <Button mr="30px" arrowDown>
                    <div
                      onClick={() => {
                        goToCv();
                      }}
                    >
                      {t("btn1")}
                    </div>
                  </Button>
                  {/*                   <Link
                    to={"/masonry-2-column"}
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                  >
                    <Button arrowRight>{t('btn2')}</Button>
                  </Link>
                   */}
                </Box>
              </Box>
            </Col>
            {/*             <Col lg="5" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <ImgRight src={imgL} alt="" />
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </Section>
    </>
  );
}

export default withNamespaces()(Hero);
