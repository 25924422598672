import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home/Home";
import ProjectsPage from "./pages/Projects";
import PostsPage from "./pages/Posts";
import axios from "axios";
import PostDetail from "./pages/PostDetail";
import ProjectDetail from "./pages/ProjectDetail";
import GlobalContext from "./context/GlobalContex";
import Loader from "./components/Loader/Loader";
//import "semantic-ui-css/semantic.min.css";

function Routes() {
  const [posts, setPosts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const gContext = useContext(GlobalContext);

  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await axios.get(
          "https://nikol-api.fly.dev/post/paginate?order=ASC&page=1&take=50"
        );
        setPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    }

    async function fetchProjects() {
      try {
        const response = await axios.get(
          "https://nikol-api.fly.dev/project/paginate?order=ASC&page=1&take=50"
        );
        setProjects(response.data.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    }

    async function fetchUser() {
      try {
        const response = await axios.get("https://nikol-api.fly.dev/user/1");
        gContext.changeUser(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    }

    fetchPosts();
    fetchProjects();
    fetchUser();
    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BASE_DOMAIN = process.env.REACT_APP_BASE_URL;

  return (
    <Router basename={BASE_DOMAIN}>
      <Switch>
        {!isLoaded ? (
          <Loader
            text={"Fetching the data for you. \n This might take a minute..."}
          />
        ) : (
          <>
            <Route exact path="/" component={Home} />
            <Route path="/projects" component={ProjectsPage} />
            <Route path="/posts" component={PostsPage} />
            {posts.map((post) => (
              <Route
                key={post.uuid}
                path={`/post/${post.uuid}`}
                render={() => <PostDetail post={post} />}
              />
            ))}
            {projects.map((project) => (
              <Route
                key={project.uuid}
                path={`/project/${project.uuid}`}
                render={() => <ProjectDetail project={project} />}
              />
            ))}
          </>
        )}
      </Switch>
    </Router>
  );
}

export default Routes;
