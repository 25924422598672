import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { Title, Section, Box, LinkContact } from "../../components/Core";
/* import bgFooter from "../../public/assets/image/png/subscribe-pattern.png"; */
import axios from "axios";

const SectionStyled = styled(Section)`
  &.pattern::before {
    position: absolute;
    bottom: -150px;
    content: "";
    width: 120%;
    height: 150%;
    background: url("/assets/image/png/subscribe-pattern.png") bottom center
      no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const Contact = ({ t, pattern = true }) => {
  const [email, setEmail] = useState("");

  async function fetchEmail() {
    const response = await axios.get("https://nikol-api.fly.dev/user/1");

    setEmail(response.data.email);
  }

  useEffect(() => {
    fetchEmail();
  }, []);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled
        className={`position-relative ${pattern ? "pattern" : ""}`}
      >
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="11" xl="10" className="text-center">
              <Box>
                <Title variant="secSm">{t("getInTouch")}</Title>

                <Box
                  className="text-center d-flex justify-content-center"
                  mt={["3rem", null, "4rem"]}
                >
                  <div className="d-flex flex-column flex-lg-row justify-content-center">
                    <LinkContact
                      href={`mailto:${email}`}
                      target="_blank"
                      className="mb-2 mb-lg-0"
                    >
                      {email}
                    </LinkContact>
                  </div>
                </Box>
                <div className="d-flex flex-column flex-lg-row justify-content-center pt-5">
                  <LinkContact
                    href="https://linkedin.com/in/petar-gojun"
                    target="_blank"
                    className="mb-2 mb-lg-0"
                  >
                    Created by Petar Gojun
                  </LinkContact>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default withNamespaces()(Contact);
