import React, { useState, useEffect, useContext } from "react";
import { Container, Col } from "react-bootstrap";
import Masonry from "react-masonry-component";
import { Section, Box } from "../../components/Core";
import GlobalContext from "../../context/GlobalContex";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import axios from "axios";

const Projects = () => {
  const gContext = useContext(GlobalContext);

  const [items, setItems] = useState([]);

  const { language } = gContext; // Access language from the global context

  async function fetchProjects() {
    const response = await axios.get(
      `https://nikol-api.fly.dev/project/paginate?order=ASC&page=1&take=50`
    );

    const langFiltered = filterLanguage(response.data.data, language);

    setItems(langFiltered);
  }

  function filterLanguage(items, language) {
    // Create a copy of the items array
    const updatedItems = [...items];

    for (let i = 0; i < updatedItems.length; i++) {
      const project = updatedItems[i];

      // Filter projectTranslations to keep only "en" locale
      project.projectTranslations = project.projectTranslations.filter(
        (translation) => translation.locale === language
      );
    }

    return updatedItems;
  }

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const masonryOptions = {
    transitionDuration: 1000,
  };

  return (
    <>
      {/* <!-- Works Area --> */}
      <Section className="position-relative">
        <Container>
          <Box mb="2.5rem" ml="-1.75rem"></Box>
        </Container>

        <Container>
          <Masonry
            options={masonryOptions}
            className={"masonry-grid row"} // default ''
          >
            {items.map((item, index) => (
              <Col lg="6" md="6" sm="12" key={index} className="filtr-item">
                {item.name}
                <ProjectCard workItem={item} mb="30px" />
              </Col>
            ))}
          </Masonry>
          {/*
  !!!WILL NEED TO BE IMPLEMENTED WHEN MORE PROJECTS ARE ADDED!!!
<Box pt="3rem" className="text-center">
            <ButtonOutline>Load more works</ButtonOutline>
          </Box> */}
        </Container>
      </Section>
    </>
  );
};

export default Projects;
