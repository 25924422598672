import React from "react";
import styled, { keyframes } from "styled-components";

// Define keyframe for loader animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled components for the loader
const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoaderSpinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: ${spin} 1s linear infinite; /* Apply animation */
`;

const LoaderText = styled.p`
  margin-top: 10px;
  color: #fff;
  font-size: 40px;
`;

// Loader component
const Loader = ({ text }) => (
  <LoaderOverlay>
    <LoaderContainer>
      <LoaderSpinner />
      {text && <LoaderText>{text}</LoaderText>}
    </LoaderContainer>
  </LoaderOverlay>
);

export default Loader;
