import React, { useState, useEffect, useContext, useRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ThemeSwitch from "../ThemeSwitch";
import ContactModal from "../ContactModal";
import AboutModal from "../AboutModal";
import GlobalContext from "../../context/GlobalContex";
import GlobalStyle from "../../utils/globalStyle";
import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../../utils";

const modes = { light: "light", dark: "dark" };

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const Layout = ({ children }) => {
  const gContext = useContext(GlobalContext);
  const [visibleLoader, setVisibleLoader] = useState(true);

  useEffect(() => {
    AOS.init();
    setVisibleLoader(false);
  }, []);

  const eleRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeAbout();
        gContext.closeContact();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeAbout();
        gContext.closeContact();
      },
      false
    );

    return () => {};
  }, [gContext]);

  return (
    <ThemeProvider
      theme={
        gContext.theme.bodyDark ? getTheme(modes.dark) : getTheme(modes.light)
      }
    >
      <ThemeSwitch />
      <GlobalStyle />
      <Helmet>
        <title>Nikol's Art</title>
        <link rel="icon" type="image/png" /* href={imgFavicon} */ />
      </Helmet>
      <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
        <div className="load-circle">
          <span className="one"></span>
        </div>
      </Loader>
      <div className="site-wrapper overflow-hidden" ref={eleRef}>
        <Header isDark={gContext.theme.headerDark} />
        {children}

        <Footer isDark={gContext.theme.footerDark} />
      </div>
      <AboutModal />
      <ContactModal />
    </ThemeProvider>
  );
};

export default Layout;
