import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Container, Carousel } from "react-bootstrap";
import { Title, Section, Box } from "../../components/Core";
import axios from "axios";
import GlobalContext from "../../context/GlobalContex";

const WorkBox = styled(Box)``;
const TextBox = styled(Box)`
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  left: 20px;
  right: 20px;
  opacity: 0;
  border-radius: 8px;
  z-index: 1;
  padding: 1.25rem 1.875rem;
  transition: 0.4s;
  &::before {
    position: absolute;
    content: "";
    background: ${({ theme }) => theme.colors.bg};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.9;
  }
  ${WorkBox}: bottom: 20px;
  opacity: 1;
`;

const Works = () => {
  const gContext = useContext(GlobalContext);
  const language = gContext.language;

  const [items, setItems] = useState([]);

  async function fetchProjects() {
    const response = await axios.get(
      `https://nikol-api.fly.dev/project/paginate?order=ASC&page=1&take=50`
    );
    const langFiltered = filterLanguage(response.data.data, language);

    const filteredData = langFiltered.filter(
      (item) => item.projectImages.length > 0
    );

    setItems(filteredData);
  }

  function filterLanguage(items, language) {
    // Create a copy of the items array
    const updatedItems = [...items];

    for (let i = 0; i < updatedItems.length; i++) {
      const project = updatedItems[i];

      // Filter projectTranslations to keep only "en" locale
      project.projectTranslations = project.projectTranslations.filter(
        (translation) => translation.locale === language
      );
    }

    return updatedItems;
  }

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <>
      {/* <!-- Works Area --> */}
      <Section>
        <Container>
          <Carousel>
            {items.map((data) => (
              <Carousel.Item>
                <a href={`/project/${data.uuid}`}>
                  <img
                    className="d-block w-100"
                    src={data?.projectImages[0]?.image?.url}
                    alt="slide"
                  />
                  <Carousel.Caption>
                    <WorkBox>
                      <TextBox>
                        <Title variant="card">
                          {data?.projectTranslations[0]?.title}
                        </Title>
                      </TextBox>
                    </WorkBox>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
            ))}
          </Carousel>

          {/* </Row>
           */}
        </Container>
      </Section>
    </>
  );
};

export default Works;
